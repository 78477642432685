import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppLinks from './AppLinks'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import IconLinks from './IconLinks'
import News from './News'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: '#ffff00',
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(2),
    '&:disabled': {
      backgroundColor: 'DimGray',
      color: 'LightGray',
      textDecoration: 'line-through'
    }
  },
  content: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(6),
    '&.small': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  ellipsis: {
    height: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  inner: {
    margin: '0 auto 0 auto',
    maxWidth: '1400px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '90%',
    zIndex: 1
  },
  links: {
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(6),
    maxWidth: '1400px',
    position: 'relative',
    '&.small': {
      marginBottom: 0,
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0)
    }
  },
  myTime: {
    color: '#fff',
    fontSize: '32pt',
    fontWeight: 700,
    '&.small': {
      fontSize: '26pt'
    }
  },
  outer: {
    background: 'linear-gradient(#003d6e 100px, #1d6092 400px)',
    backgroundColor: '#003d6e',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    '&::after': {
      background: 'transparent url("/img/mytime.svg") no-repeat left top',
      backgroundPosition: '90%, 0',
      backgroundSize: '60% auto',
      content: '""',
      height: '100%',
      opacity: 0.1,
      position: 'fixed',
      right: '-60px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        backgroundPosition: '60%, 0',
        backgroundSize: '120% auto',
        right: '-16px'
      }
    }
  },
  welcome: {
    color: '#fff',
    fontSize: '9pt',
    fontWeight: 400
  }
}))

const urls = {
  urlProdTest: 'https://shb.ais.ucla.edu/shibboleth-idp/profile/SAML2/Unsolicited/SSO?providerId=https://test.kronos.ucla.edu/shibboleth',
  urlProd: 'https://shb.ais.ucla.edu/shibboleth-idp/profile/SAML2/Unsolicited/SSO?providerId=https://kronos.ucla.edu/shibboleth'
}

export default function Headline (props) {
  const classes = useStyles()

  const theme = useTheme()

  const small = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })

  const onClick = () => {
    window.location.href = urls.urlProd
  }

  return (
    <div className={classes.outer}>
      <div className={classes.inner}>
        <div className={clsx(classes.content, {
          small: small
        })}>
          <News active={false} />
          <Typography
            classes={{ root: classes.welcome }}
            variant="h6"
            component="h1"
            gutterBottom
          >
            <img
              alt=""
              aria-hidden="true"
              className={classes.ellipsis}
              src="/img/ellipsis.svg"
            />WELCOME TO
          </Typography>
          <Typography
            className={clsx(classes.myTime, {
              small: small === true
            })}
            variant="h4"
            component="h1"
            gutterBottom
          >
            MyTime
          </Typography>
          <Button
            classes={{
              root: classes.button,
              disabled: classes.disabled
            }}
            disabled={false}
            onClick={onClick}
            size="large"
            variant="contained"
          >
            Sign In With UCLA ID
          </Button>
          <AppLinks />
        </div>
        <div className={clsx(classes.links, {
          small: small === true
        })}>
          <IconLinks
            handleMenuItem={props.handleMenuItem}
            menuItems={props.menuItems}
          />
        </div>
      </div>
    </div>
  )
}
Headline.propTypes = {
  handleMenuItem: PropTypes.func,
  menuItems: PropTypes.array,
  setOpenMenu: PropTypes.func
}
