import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: theme.spacing(2)
  },
  last: {
    marginBottom: theme.spacing(5)
  }
}))

export default function About (props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <span id="about"></span>
      <Typography
        classes={{ root: classes.heading }}
        component="h2"
        variant="h5"
      >
        About
      </Typography>
      <Typography
        component="p"
        paragraph
        varient="body2"
      >
        MyTime is UCLA&apos;s easy-to-navigate website for the Kronos timekeeping system. Think of MyTime as your front door to Kronos, a helpful entry point secured with a single key—your UCLA Logon, making access to Kronos simple and more intuitive.
      </Typography>
      <Typography
        classes={{ root: classes.last }}
        component="p"
        paragraph
        varient="body2"
      >
        Kronos is an automated time-and-attendance and scheduling system used by many administrative departments at UCLA. Kronos provides a cloud-based data storage solution, along with data-collecting time clocks, to manage timekeeping for a large, diverse, geographically dispersed organization like UCLA.
      </Typography>
    </React.Fragment>
  )
}
