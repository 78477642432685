import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Link from '@material-ui/core/Link'
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  appIcon: {
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    height: theme.spacing(4),
    marginRight: theme.spacing(1)
  },
  appIcons: {
    marginBottom: theme.spacing(4)
  },
  container: {
    width: '100%'
  },
  getApps: {
    color: '#fff',
    fontSize: '10pt',
    fontStyle: 'oblique',
    fontWeight: 400,
    marginBottom: theme.spacing(3)
  },
  item: {
    alignSelf: 'flex-start',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(4)
  }
}))

export default function AppLinks (props) {
  const classes = useStyles()

  const [open, _setOpen] = useState(false)

  const [url, _setUrl] = useState('')

  const handleOpen = (device) => (event) => {
    event.preventDefault()
    _setOpen(true)
    _setUrl(device === 'apple' ? 'https://apps.apple.com/us/app/kronos-mobile/id404059113' : 'https://play.google.com/store/apps/details?id=com.kronos.mobile.android')
  }

  const handleClose = () => {
    _setOpen(false)
  }

  const handleYesGotIt = () => {
    _setOpen(false)
    window.open(url, '_blank')
  }

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Typography
          classes={{ root: classes.getApps }}
          variant="h5"
          component="h2"
        >
          Get the Kronos app for your mobile device and take MyTime wherever you go!
          <br />(Department/supervisor approval required.)
        </Typography>
        <div className="appIcons">
          <Link
            aria-label="Download on the Apple App Store"
            href="#"
            onClick={handleOpen('apple')}
          >
            <img
              alt="Download on the Apple App Store"
              src="/img/apple_app_store.svg" className={classes.appIcon}
            />
          </Link>
          <Link
            aria-label="Get it on Google Play"
            href="#"
            onClick={handleOpen('android')}
          >
            <img
              alt="Get it on Google Play"
              src="/img/google_play.svg" className={classes.appIcon}
            />
          </Link>
        </div>
      </div>
      <Dialog
        open={open}
        keepMounted
        aria-describedby="dialogDescription"
      >
        <DialogContent>
          <DialogContentText id="dialogDescription">
            You must have department/supervisor approval to use Kronos from your mobile device.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYesGotIt} color="primary">
            Yes, got it
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
