import { makeStyles, useTheme } from '@material-ui/core/styles'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

const systemReq = [
  {
    requirement: 'Operating System',
    items: [
      'Microsoft Windows 7 (32- and 64-bit)',
      'Mac OS X 10.13'
    ],
    note: ''
  },
  {
    requirement: 'Browser',
    items: [
      'Microsoft Internet Explorer 11',
      'Mozilla Firefox Next 69+',
      'Chrome 62+',
      'Safari 11'
    ],
    note: ''
  },
  {
    requirement: 'Plug-in',
    items: [
      'None needed'
    ],
    note: ''
  }
]

const useStyles = makeStyles(theme => ({
  heading: {
    marginTop: theme.spacing(5)
  },
  tableContainer: {
    marginBottom: theme.spacing(5)
  }
}))

export default function SystemReq (props) {
  const classes = useStyles()

  const theme = useTheme()

  return (
    <React.Fragment>
      <span id="sys"></span>
      <Typography
        classes={{ root: classes.heading }}
        component="h2"
        variant="h5"
      >
        System Requirements
      </Typography>
      <TableContainer classes={{ root: classes.tableContainer }}>
        <Table aria-label="System Requirements">
          <TableBody>
            {systemReq.map((sR, x) => (
              <TableRow key={x}>
                <TableCell component="th" scope="row">
                  {sR.requirement}
                </TableCell>
                <TableCell align="left">
                  <ul>
                    {sR.items.map((i, y) => (
                      <li key={y}>{i}</li>
                    ))}
                  </ul>
                  {sR.note && (
                    <Typography
                      paragraph
                      style={{ marginLeft: theme.spacing(4) }}
                      variant="body2"
                    >
                      {sR.note}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}
