import { makeStyles, useTheme } from '@material-ui/core/styles'
import './App.css'
import About from './About'
import Drawer from '@material-ui/core/Drawer'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Faq from './Faq'
import Footer from './Footer'
import Header from './Header'
import Headline from './Headline'
import IconButton from '@material-ui/core/IconButton'
import Menu from './Menu'
import React, { useState } from 'react'
import SystemReq from './SystemReq'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const menuItems = [
  {
    description: 'Friendly support staff are available to help you with MyTime during regular business hours.',
    heading: 'Need Help? Call 310.206.7888',
    headingShort: 'Need Help?',
    icon: 'icon_helpline',
    url: '#help'
  },
  {
    description: 'Technical information about how to configure your computer for MyTime.',
    heading: 'System Requirements',
    headingShort: 'System Requirements',
    icon: 'icon_system',
    url: '#sys'
  },
  {
    description: 'Answers to frequently asked questions about accounts, system requirements, and how to use MyTime.',
    heading: 'Frequently Asked Questions',
    headingShort: 'FAQ',
    icon: 'icon_faq',
    url: '#faq'
  },
  {
    description: 'Learn how MyTime can help your UCLA department manage your workforce.',
    heading: 'About',
    headingShort: 'About',
    icon: 'icon_info',
    url: '#about'
  }
]

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto 0 auto',
    maxWidth: '1400px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '90%'
  },
  paper: {
    minWidth: theme.spacing(40)
  }
}))

function App (props) {
  const classes = useStyles()

  const theme = useTheme()

  const small = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })

  const [openMenu, _setOpenMenu] = useState(false)

  const handleOpen = () => {
    _setOpenMenu(true)
  }

  const handleClose = () => {
    _setOpenMenu(false)
  }

  const handleMenuItem = (x) => (event) => {
    if (event.type === 'click' || (event.type === 'keydown' && event.key === 'Enter')) {
      const item = menuItems[x]
      if (item.url.indexOf('#') === 0) {
        if (item.url === '#help') {
          let params = 'scrollbars=yes,resizable,status=no,location=no,toolbar=no,menubar=no'
          params += !small ? ',width=640,height=360,left=64,top=64' : ''
          window.open('../Form.aspx', 'helpWindow', params)
        } else {
          const elmnt = document.querySelector(item.url)
          if (elmnt) {
            const top = elmnt.offsetTop - 112
            window.scrollTo(0, top)
          }
        }
      } else {
        window.location.href = item.url
      }
      handleClose()
    }
  }

  return (
    <React.Fragment>
      <span id='top'></span>
      <Header
        handleMenuItem={handleMenuItem}
        handleOpen={handleOpen}
        menuItems={menuItems}
      />
      <Drawer
        anchor="left"
        classes={{ paper: classes.paper }}
        open={openMenu}
        onClose={handleClose}
      >
        <IconButton
          aria-label="Collapse menu"
          onClick={handleClose}
          style={{ alignSelf: 'flex-end' }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Menu
          handleClose={handleClose}
          handleMenuItem={handleMenuItem}
          handleOpen={handleOpen}
          menuItems={menuItems}
        />
      </Drawer>
      <Headline
        handleMenuItem={handleMenuItem}
        menuItems={menuItems}
      />
      <div className={classes.content}>
        <SystemReq />
        <Faq />
        <About />
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default App
