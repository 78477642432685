import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import Link from '@material-ui/core/Link'
import Logo from './logo_ucla.svg'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  contact: {
    margin: '0 auto 0 auto',
    maxWidth: '1400px',
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(6),
    width: '90%',
    '&.small': {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%'
    }
  },
  contactContainer: {
    background: 'linear-gradient(#000000 10px, #333333 200px)'
  },
  contactLinks: {
    borderBottom: '1px solid #2d79ab',
    borderTop: '1px solid #2d79ab',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    '&.small': {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  copy: {
    color: '#fff',
    textAlign: 'center',
    '&.small': {
      marginBottom: theme.spacing(2)
    }
  },
  legal: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto 0 auto',
    maxWidth: '1400px',
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(6),
    width: '90%'
  },
  legalContainer: {
    background: 'linear-gradient(#003d6e 10px, #1d6092 200px)'
  },
  legalLink: {
    color: '#ffe800',
    fontSize: '.8rem',
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  legalLinks: {
    color: '#ffe800',
    marginBottom: theme.spacing(2),
    zIndex: 4
  },
  link: {
    color: '#fff',
    fontSize: '.9rem',
    fontWeight: 600,
    lineHeight: 1.5,
    textTransform: 'uppercase',
    zIndex: 4
  },
  logo: {
    display: 'flex',
    height: theme.spacing(4),
    marginBottom: theme.spacing(2)
  }
}))

export default function Footer (props) {
  const classes = useStyles()

  const theme = useTheme()

  const small = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })

  return (
    <React.Fragment>
      <div className={classes.contactContainer}>
        <div
          className={clsx(classes.contact, {
            small: small
          })}
        >
          <div
            className={clsx(classes.contactLinks, {
              small: small
            })}
          >
            <Link
              className={classes.link}
              href="http://kronos.uclasf1.acsitefactory.com/"
            >
              Kronos Service Center
            </Link>
            <Link
              className={classes.link}
              href="tel:310-206-7888"
            >
              (310) 206-7888
            </Link>
            <Link
              className={classes.link}
              href="mailto:kronossc@facnet.ucla.edu"
            >
              kronossc@facnet.ucla.edu
            </Link>
          </div>
        </div>
      </div>
      <div className={classes.legalContainer}>
        <div className={classes.legal} >
          <img
            className={classes.logo}
            alt="UCLA"
            src={Logo}
          />
          <div className={classes.legalLinks}>
            <Link
              className={classes.legalLink}
              href="https://ucla.edu"
            >
              UCLA.EDU
            </Link>
            &nbsp;|&nbsp;
            <Link
              className={classes.legalLink}
              href="https://www.ucla.edu/terms-of-use/"
            >
              Terms of Use
            </Link>
          </div>
          <Typography
            classes={{ root: classes.copy }}
            className={clsx({
              small: small
            })}
            component="p"
            variant="body2"
          >
            &copy;2020 Regents of the University of California • All Rights Reserved
          </Typography>
        </div>
      </div>
    </React.Fragment>
  )
}
