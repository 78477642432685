import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import LogoUcla from './logo_ucla.svg'
import Menu from './Menu'
import MenuIcon from '@material-ui/icons/Menu'
import PropTypes from 'prop-types'
import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: '#003D6E',
    flexDirection: 'column',
    margin: '0 auto 0 auto'
  },
  container: {
    display: 'flex',
    margin: '0 auto 0 auto',
    maxWidth: '1400px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '90%',
    '&.small': {
      paddingLeft: theme.spacing(0)
    }
  },
  logo: {
    height: theme.spacing(3.5),
    '&.small': {
      height: theme.spacing(3)
    }
  },
  logoContainer: {
    alignItems: 'flex-end',
    cursor: 'pointer',
    display: 'flex',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: '100%'
  },
  logoFont: {
    fontSize: '18pt',
    fontWeight: 700,
    lineHeight: 1,
    marginBottom: '-3px',
    marginLeft: theme.spacing(1),
    '&.small': {
      fontSize: '15pt',
      marginBottom: '-2px',
      marginLeft: theme.spacing(0.5)
    }
  }
}))

export default function Header (props) {
  const classes = useStyles()

  const theme = useTheme()

  const small = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })

  const handleHome = () => {
    const elmnt = document.getElementById('top')
    if (elmnt) {
      window.scrollTo(0, elmnt.offsetTop)
    }
  }

  return (
    <AppBar
      classes={{ root: classes.appBar }}
      position="sticky"
    >
      <Box
        className={clsx(classes.container, {
          small: small === true
        })}
      >
        {small && (
          <IconButton
            aria-controls="menu"
            aria-haspopup="true"
            aria-label="Menu"
            onClick={props.handleOpen}
          >
            <MenuIcon style={{ color: '#fff' }}/>
          </IconButton>
        )}
        <Box
          className={classes.logoContainer}
          onClick={handleHome}
        >
          <img
            alt="UCLA"
            className={clsx(classes.logo, {
              small: small === true
            })}
            src={LogoUcla}
          />
          <span
            className={clsx(classes.logoFont, {
              small: small === true
            })}
          >MyTime</span>
        </Box>
      </Box>
      {!small && (
        <Menu
          handleClose={props.handleClose}
          handleMenuItem={props.handleMenuItem}
          menuItems={props.menuItems}
          small={false}
        />
      )}
    </AppBar>
  )
}
Header.propTypes = {
  handleClose: PropTypes.func,
  handleMenuItem: PropTypes.func,
  handleOpen: PropTypes.func,
  menuItems: PropTypes.array
}
