import { makeStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  buttonBase: {
    borderRadius: '10%',
    display: 'block',
    maxWidth: theme.spacing(24),
    opacity: 1.0,
    zIndex: 1,
    '&:hover': {
      opacity: 0.7
    }
  },
  description: {
    color: '#fff'
  },
  heading: {
    color: '#fff',
    fontWeight: 600
  },
  icon: {
    height: theme.spacing(12),
    width: 'auto'
  }
}))

export default function IconLink (props) {
  const classes = useStyles()

  return (
    <ButtonBase
      classes={{ root: classes.buttonBase }}
      onClick={props.handleMenuItem(props.x)}
    >
      <img
        alt=""
        aria-hidden="true"
        className={classes.icon}
        src={'/img/' + props.icon + '.svg'}
      />
      <Typography
        classes={{ root: classes.heading }}
        component="h2"
        variant="body1"
      >
        {props.heading}
      </Typography>
      <Typography
        classes={{ root: classes.description }}
        component="p"
        variant="body2"
      >
        {props.description}
      </Typography>
    </ButtonBase>
  )
}
IconLink.propTypes = {
  description: PropTypes.string,
  handleMenuItem: PropTypes.func,
  heading: PropTypes.string,
  icon: PropTypes.string,
  url: PropTypes.string,
  x: PropTypes.number
}
