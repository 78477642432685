import { makeStyles, useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import PropTypes from 'prop-types'
import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    color: '#fff',
    '&:disabled': {
      color: '#FFD100'
    },
    '&:hover': {
      color: '#FFD100'
    },
    '&:not(:last-child)': {
      borderRight: 'none'
    }
  },
  paper: {
    minWidth: theme.spacing(40)
  },
  toolBar: {
    margin: '0 auto 0 auto',
    maxWidth: '1400px',
    minHeight: '48px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '90%'
  }
}))

export default function Menu (props) {
  const classes = useStyles()

  const theme = useTheme()

  const small = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })

  return (
    <React.Fragment>
      {!small && (
        <Toolbar
          classes={{ root: classes.toolBar }}
          disableGutters={true}
        >
          <ButtonGroup
            aria-label="Menu"
            classes={{
              groupedTextPrimary: classes.buttonGroup
            }}
            color="primary"
            variant="text"
          >
            {props.menuItems.map((item, x) => (
              <Button
                key={x}
                onClick={props.handleMenuItem(x)}
              >{item.headingShort}</Button>
            ))}
          </ButtonGroup>
        </Toolbar>
      )}
      {small && (
        <List disablePadding={true}>
          {props.menuItems.map((item, x) => (
            <ListItem
              button
              key={x}
              onClick={props.handleMenuItem(x)}
            >
              <ListItemText primary={item.headingShort} />
            </ListItem>
          ))}
        </List>
      )}
    </React.Fragment>
  )
}
Menu.propTypes = {
  handleClose: PropTypes.func,
  handleMenuItem: PropTypes.func,
  menuItems: PropTypes.array
}
