import { makeStyles, withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'

const screens = [
  {
    id: '1_1',
    file: '8e2431dcf24e5a041e94cba43299ba28c7b4b2f1a95e6f69244d15b79f137bd7_3-18-2014_10-39-06_AM.png'
  },
  {
    id: '2_1',
    file: 'CVS.jpg'
  }
]

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails)

const useStyles = makeStyles(theme => ({
  accordianDetails: {
    flexDirection: 'column'
  },
  container: {
    marginBottom: theme.spacing(5)
  },
  heading: {
    marginBottom: theme.spacing(2),
    scrollMargin: theme.spacing(8)
  },
  iconButt0n: {
    alignSelf: 'flex-start'
  },
  li: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2)
    }
  },
  paper: {
    display: 'block',
    margin: 0
  },
  paperScrollPaperr: {
    display: 'block'
  },
  screen: {
    maxWidth: '100%'
  },
  thumbnail: {
    display: 'inline-block',
    marginTop: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

export default function Faq (props) {
  const classes = useStyles()

  const [expanded, setExpanded] = useState('')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const [file, _setFile] = useState(null)

  const [open, _setOpen] = React.useState(false)

  const handleClick = (id) => {
    const result = screens.filter(screen => screen.id === id)
    if (result.length) {
      _setFile(result[0].file)
      _setOpen(true)
    }
  }

  const handleClose = () => {
    _setOpen(false)
  }

  return (
    <React.Fragment>
      <span id="faq"></span>
      <Typography
        classes={{ root: classes.heading }}
        component="h2"
        gutterBottom={true}
        variant="h5"
      >
        Frequently Asked Questions
      </Typography>
      <div className={classes.container}>
        <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>
              I forgot my username and/or password. What should I do?
            </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordianDetails }}>
            <ol>
              <li className={classes.li}>
                Visit <Link href="https://accounts.iam.ucla.edu/">UCLA Bruin OnLine</Link> to inquire/resetyour credentials.
                <br />
                <span className={classes.thumbnail} onClick={() => handleClick('1_1')}>
                  <img alt="Screen shot" src="img/screen/s/8e2431dcf24e5a041e94cba43299ba28c7b4b2f1a95e6f69244d15b79f137bd7_3-18-2014_10-39-06_AM_big.png" />
                </span>
              </li>
              <li className={classes.li}>
                Reset username/password. You will need to contact BOL support at <Link href="tel:13102674357">(310) 267-4357</Link> to request your username/password reset.</li>
            </ol>
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>
              I&apos;ve been told I need to disable compatibility view for MyTime. How do I do this?
            </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordianDetails }}>
            <ol>
              <li className={classes.li}>
                Open Internet Explorer. From your Start&quot; button select &quot;Internet Explorer&quot;.</li>
              <li className={classes.li}>
                Open &quot;Tools&quot;. Tap the &quot;Alt&quot; key and select &quot;Tools&quot; then &quot;Compatibility View Settings&quot;.</li>
              <li className={classes.li}>
                Select the &quot;ucla.edu&quot; entry and click &quot;Remove&quot;. Click on the &quot;ucla.edu&quot; entry in the left window to highlight it. Click the &quot;Remove&quot; button to the right.
                <br />
                <span className={classes.thumbnail} onClick={() => handleClick('2_1')}>
                  <img alt="Screen shot" src="img/screen/s/thumb_CVS.jpg" />
                </span>
              </li>
              <li className={classes.li}>
                Click &quot;Close&quot;. Close the &quot;Tools&quot; window.</li>
            </ol>
          </AccordionDetails>
        </Accordion>
      </div>
      <Dialog
        classes={{
          paper: classes.paper,
          paperScrollPaper: classes.paperScrollPaper
        }}
        open={open}
      >
        <IconButton
          aria-label="Close"
          classes={{ root: classes.iconButton }}
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <div>
          <img
            alt="Screen shot"
            className={classes.screen}
            src={'img/screen/l/' + file}
          />
        </div>
      </Dialog>
    </React.Fragment>
  )
}
