import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconLink from './IconLink'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%'
  },
  item: {
    alignSelf: 'flex-start',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(4)
  }
}))

export default function IconLinks (props) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={0}
      >
        {props.menuItems.map((i, x) => (
          <Grid
            classes={{ item: classes.item }}
            item
            key={x}
            md={3} sm={6} xs={12}
          >
            <IconLink
              description={i.description}
              handleMenuItem={props.handleMenuItem}
              heading={i.heading}
              icon={i.icon}
              url={i.url}
              x={x}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
IconLinks.propTypes = {
  handleMenuItem: PropTypes.func,
  menuItems: PropTypes.array
}
