import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const news = {
  heading: 'MyTime currently unavailable',
  body: 'MyTime is being updated to a new and improved version and will be offline until 8 p.m. Sunday, August 16.',
  url: ''
}

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'rgba(255, 0, 0, 0.6)',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    color: '#fff',
    marginBottom: theme.spacing(6),
    padding: theme.spacing(2)
  },
  link: {
    color: '#fff'
  }
}))

export default function News (props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      {props.active && (
        <div className={classes.container}>
          <Typography
            component="h2"
            variant="h5"
          >
            {news.heading}
          </Typography>
          <Typography
            component="p"
            gutterBottom
            variant="body1"
          >
            {news.body}
          </Typography>
          {news.url && (
            <Typography
              component="p"
              variant="body1"
            >
              <Link
                classes={{ root: classes.link }}
                href={news.url}
              >
                More information...
              </Link>
            </Typography>
          )}
        </div>
      )}
    </React.Fragment>
  )
}
News.propTypes = {
  active: PropTypes.bool
}
